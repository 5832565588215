<template>
  <div>
    <loader v-if="loading" />
    <form class="container" v-else>
      <h1>{{ langs[lang].c.title }}</h1>

      <fieldset>
        <legend>
          <span class="number">1</span> {{ langs[lang].c.params }}
        </legend>
        <label for="start_date">{{ langs[lang].start_date }}:</label>
        <v-select
          id="start_date"
          v-model="start_date"
          :options="parameters"
        ></v-select>

        <label for="end_date">{{ langs[lang].end_date }}:</label>
        <v-select
          id="end_date"
          v-model="end_date"
          :options="parameters"
        ></v-select>
      </fieldset>

      <fieldset v-if="start_date && end_date && start_date !== end_date">
        <legend><span class="number">2</span> {{ langs[lang].c.pref }}</legend>
        <label for="lang">{{ langs[lang].c.lang }}:</label>
        <v-select
          id="lang"
          v-model="lang"
          label="value"
          :reduce="value => value.code"
          :options="
            Object.keys(langs).map(code => ({ code, value: langs[lang][code] }))
          "
        ></v-select>
        <label for="selected">{{ langs[lang].c.selected }}:</label>
        <Multiselect
          multiple
          :close-on-select="false"
          :clear-on-select="false"
          trackBy="value"
          label="name"
          id="selected"
          v-model="selected"
          :options="items.map(value => ({ value, name: langs[lang][value] }))"
        />
      </fieldset>

      <fieldset v-if="selected.length > 0">
        <legend>
          <span class="number">3</span> {{ langs[lang].c.default }}
        </legend>
        <label for="by_default">{{ langs[lang].c.by_default }}:</label>
        <v-select
          id="by_default"
          v-model="by_default"
          label="name"
          :reduce="value => value.value"
          :options="selected"
        ></v-select>
      </fieldset>

      <button
        v-if="
          by_default &&
            selected.length > 0 &&
            start_date &&
            end_date &&
            end_date !== start_date
        "
        @click="close"
      >
        {{ langs[lang].c.save }}
      </button>
    </form>
  </div>
</template>

<script>
import mixin from "../mixin";
import "vue-select/dist/vue-select.css";
import Vue from "vue";
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import Loader from "../components/Loader";
export default {
  name: "popup",
  mixins: [mixin],
  components: { Multiselect, vSelect, Loader },
  data: () => ({
    parameters: [],
    loading: false,
    start_date: "",
    end_date: "",
    selected: [],
    lang: "en",
    by_default: ""
  }),
  methods: {
    close() {
      let obj = {
        start_date: this.start_date,
        end_date: this.end_date,
        selected: this.selected.map(s => s.value),
        by_default: this.by_default,
        lang: this.lang
      };
      for (let k in obj) {
        if (k !== "selected") {
          tableau.extensions.settings.set(k, obj[k]);
        } else {
          tableau.extensions.settings.set(k, JSON.stringify(obj[k]));
        }
      }
      tableau.extensions.ui.closeDialog(JSON.stringify(obj));
    }
  },
  mounted() {
    this.loading = true;
    tableau.extensions
      .initializeDialogAsync()
      .then(() => {
        const dash = tableau.extensions.dashboardContent.dashboard;
        return dash.getParametersAsync();
      })
      .then(params => {
        this.parameters = params
          .filter(
            p =>
              (p.dataType == "date" || p.dataType == "date-time") &&
              p.allowableValues.type == "all"
          )
          .map(p => p.name);
        this.loading = false;
      })
      .catch(err => {
        this.loading = false;
        console.log(err);
      });
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  font-family: "Nunito", sans-serif;
  color: #384047;
}

form {
  max-width: 300px;
  margin: 10px auto;
  padding: 10px 20px;
  background: #f4f7f8;
  border-radius: 8px;
}

h1 {
  margin: 0 0 30px 0;
  text-align: center;
}

button {
  padding: 19px 39px 18px 39px;
  color: #fff;
  background-color: #4bc970;
  font-size: 18px;
  text-align: center;
  font-style: normal;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  border: 1px solid #3ac162;
  border-width: 1px 1px 3px;
  box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
  margin-bottom: 10px;
}

fieldset {
  margin-bottom: 30px;
  border: none;
}

legend {
  font-size: 1.4em;
  margin-bottom: 10px;
}

label {
  display: block;
  margin-top: 8px;
  margin-bottom: 6px;
}

label.light {
  font-weight: 300;
  display: inline;
}

.number {
  background-color: #5fcf80;
  color: #fff;
  height: 30px;
  width: 30px;
  display: inline-block;
  font-size: 0.8em;
  margin-right: 4px;
  line-height: 30px;
  text-align: center;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}

@media screen and (min-width: 480px) {
  form {
    max-width: 480px;
  }
}
</style>
